
import { Audits } from "@/api";
import { Audit } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import InstructorsList from "@/components/instructors/InstructorsList.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    InstructorsList,
    PageTitle,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EditAuditPage extends Vue {
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save",
      disabled: true
    }
  ];
  headersTitle = [{ icon: "mdi-calendar-month", header: "Edit Audit" }];
  selectedInstructors: Array<number> = [];
  oldSelectedInstructors: Array<number> = [];

  audit: Audit = {
    courseDurationId: undefined,
    instructors: [],
    name: "",
    price: 0
  };
  oldAudit: Audit = {};

  durations: any = [];

  get auditId() {
    return this.$route.params.id;
  }

  async created() {
    try {
      [this.audit, this.durations] = await Promise.all([
        Audits.info(this.auditId),
        Audits.durations()
      ]);

      if (this.audit.instructors !== undefined) {
        this.selectedInstructors = this.audit.instructors.map(
          (course: { id: number; name: string }) => course.id
        );
      }
      this.oldSelectedInstructors = [...this.selectedInstructors];
      this.oldAudit = JSON.parse(JSON.stringify(this.audit));
      this.audit.courseDurationId = this.durations.find(
        (d: any) => d.day === this.audit.duration
      ).id;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  @Watch("audit", { deep: true })
  onChange() {
    // eslint-disable-next-line no-undef
    Object.keys(this.audit).forEach(key => {
      if (this.oldAudit[key as keyof Audit] != this.audit[key as keyof Audit]) {
        const index = this.buttonsArray.findIndex(b => b.action === "save");
        this.buttonsArray[index].disabled = false;
      }
    });
  }
  @Watch("selectedInstructors.length")
  handlerSelectedInstructors(newValue: number) {
    if (this.oldSelectedInstructors.length !== newValue) {
      const index = this.buttonsArray.findIndex(b => b.action === "save");
      this.buttonsArray[index].disabled = false;
    }
  }

  async save() {
    try {
      if (!this.audit.price || this.audit.price <= 0) {
        (this.$refs.price as InstanceType<
          typeof ValidationProvider
        >).applyResult({
          errors: ["is requared"], // array of string errors
          // valid: false, // boolean state
          failedRules: {} // should be empty since this is a manual error.
        });
        return;
      }
      const data = {
        name: this.audit.name,
        instructors: this.selectedInstructors,
        price: this.audit.price,
        courseDurationId: this.audit.courseDurationId
      };

      await Audits.update(String(this.audit.id), data);
      await this.$success("<strong>Success!</strong> Audit has been created!");

      if (this.$route.name === "EditAudit") {
        await this.$router.push({ name: "AuditsList" });
      }
    } catch (e) {
      const err = e as any;
      (this.$refs.auditForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }

  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }

  async onGoBack() {
    await this.$router.push({ name: "AuditsList" });
  }
}
